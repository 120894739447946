@use 'variables';
@use 'mixins';
@use 'base';
@use 'components';

// Tailwind imports after all @use statements
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: rgb(39, 38, 38);
}

::-webkit-scrollbar-thumb {
    background: #666666;
    border-radius: 12px;
    transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
    background: #666666;
}

select:focus {
    outline: 0px;
    border: none;
}

input:focus {
    outline: 0px;
    border: none;
}

.hoverModal:hover #modal {
    display: flex;
}

.react-pdf__Page__textContent {
    display: none !important;
}

.react-pdf__Page__annotations {
    display: none !important;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
}
