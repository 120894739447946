// Use namespaces when importing shared resources
@use 'variables' as *;
@use 'mixins' as *;

// Your component styles here

.hoverModal:hover #modal {
    display: flex;
}

.react-pdf {
    &__Page {
        &__textContent,
        &__annotations {
            display: none !important;
        }

        &__canvas {
            width: 100% !important;
            height: auto !important;
        }
    }
}
