@use 'variables' as *;

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: var(--border-radius);
  transition: all var(--transition-duration) ease;

  &:hover {
    background: var(--scrollbar-thumb);
  }
}

select, input {
  &:focus {
    outline: 0;
    border: none;
  }
}
